.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Footer Styling */
.footer {
  background-color: #000;
  color: white;
  padding: 20px 0;
  text-align: center;
}

.footer-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.footer-section {
  flex: 1;
  min-width: 200px;
  padding: 10px;
}

.footer-logo {
  width: 150px;
  margin-bottom: 10px;
}

.footer-section h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 5px;
}

.footer-section ul li a {
  color: white;
  text-decoration: none;
}

.footer-section ul li a:hover {
  text-decoration: underline;
}

/* Download Button */
.download-section {
  text-align: center;
  margin-top: 20px;
  position: fixed; 
  bottom: 20px; 
  right: 20px; 
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
 
}

.download-btn {
  display: inline-flex;
  align-items: center;
  background-color: #00b300;
  color: white;
  padding: 10px 20px;
  font-size: 1.2em;
  border-radius: 8px;
  text-decoration: none;
  font-weight: bold;
  box-shadow: 10px 4px 8px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
 
}

.android-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

/* Footer Bottom */
.footer-bottom {
  margin-top: 20px;
  font-size: 0.9em;
  opacity: 0.8;
}
/* Navbar Styling */
.navbar {
  background-image: linear-gradient(to top, green,black); /* Red color like in your screenshot */
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
}

.navbar-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

/* Logo */
.logo-container img {
  width: 50px;
  height: auto;
}

/* Navigation Links */
.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
}

.nav-links li {
  display: inline;
  background-color: #00b300;
  border: 1px solid rgb(2, 41, 2);
  padding: 10px;
}

.nav-links a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  transition: 0.3s;
}

.nav-links a:hover {
  color: yellow;
}

/* Mobile Menu Button */
.menu-icon {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

/* Responsive Navbar */
@media (max-width: 768px) {
  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background: #ffffff;
    padding: 10px 0;
    text-align: center;
    box-shadow: 3px 10px 10px rgb(98, 98, 98);
  }

  .nav-links.active {
    display: flex;
  }

  .menu-icon {
    display: block;
  }
}

/* Prevent content from being hidden under the fixed navbar */
body {
  padding-top: 70px;
}
.home-img{
  width: 100%;
}
.banner{
  padding-top: 40px;
  background-color: #acfdac;
  text-align: center;
  font-size: 20px;
  box-shadow: inset 0 0 10px #000000;
}
.about-content{
  text-align: left;
  margin: 20px;
}

.about-content{
  padding-top: 40px;
}
.game-rule{
  padding: 20px;
}
.game-rule{

  text-align: left;
}
.game-heading{
  background-color: #d6ffd6;
  text-align: center;
}

/* General Styling */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f2f2f2;
}

/* Contact Form Container */
.contact-container {
  max-width: 700px;
  margin: 50px auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-top: 10%;
}

/* Contact Title */
h2 {
  margin-bottom: 20px;
  color: #333;
}

/* Form Styling */
form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Form Groups */
.form-group {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.input-box {
  width: 48%;
  display: flex;
  flex-direction: column;
  text-align: left;
}

/* Labels */
label {
  font-weight: bold;
  margin-bottom: 5px;
  color: #444;
  text-align: left;
}

/* Input, Select, and Textarea */
input, select, textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  font-size: 14px;
}

/* Placeholder Text Styling */
input::placeholder, textarea::placeholder {
  color: #999;
}

/* Textarea */
textarea {
  resize: vertical;
  min-height: 100px;
}

/* Send Button */
button {
  background: green;
  color: white;
  padding: 12px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

button:hover {
  background: darkgreen;
}

/* Contact Info Section */
.contact-info {
  margin-top: 20px;
  text-align: center;
}

.contact-info p {
  margin: 5px 0;
  font-size: 14px;
  color: #333;
}

/* Highlighted Text */
.highlight {
  font-weight: bold;
  color: black;
}

/* Email Link */
a {
  text-decoration: none;
  font-weight: bold;
  color: black;
}

a:hover {
  color: green;
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .form-group {
    flex-direction: column;
  }

  .input-box {
    width: 100%;
  }

  .heading {
    font-size: 25px;
    background-image: radial-gradient(circle,green,black);
    color: #fff;
    padding: 10px;
  }

  .contact-container {
    max-width: 500px;
    margin: 20px;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    text-align: left;
    margin-top: 10%;
  }

  input, select, textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 90%;
    font-size: 14px;
  }
  
}

.heading {

  background-image: radial-gradient(circle,rgb(209, 111, 0),rgb(0, 48, 10));
  color: #fff;
  padding: 10px;
}
.terms-container{
  text-align: left;
  margin: 30px;
}

.refund-container{
  text-align: left;
  margin: 30px;
}
.rum-head{
  text-align: left;
}
.responsible-container{
  margin: 30px;
}

.contact-heading{
  text-align: center;
  border-bottom: 1px solid rgb(169, 169, 169);
  padding-bottom: 10px;
}

.headings{
  background-color: #ddffdd;
  text-align: center;
  padding: 15px;
}